import { editorSDK, initContext } from "./context";
import {
  isAnyCommercialVerticalInstalled,
  isCommercialVertical,
  isMembersInstalled,
  isMembersVertical,
  isWalletInstalled
} from "./appsUtils";
import {
  installCashiersRouter,
  installWallet,
  installWalletDependencies,
  isInstallingLocked,
  lockInstalling,
  unlockInstalling
} from "./installWallet";
import { fetchExperiments } from "./fetchExperiments";

let isSaveCreditCardEnabled = false;
let isEnableWalletAutoDetectingEnabled = false;

export const editorReady = async (passedEditorSDK, appToken, options) => {
  initContext(appToken, passedEditorSDK);

  const experiments = await fetchExperiments();
  isSaveCreditCardEnabled = experiments.enabled("specs.cashier.SaveCreditCard");
  isEnableWalletAutoDetectingEnabled = experiments.enabled(
    "specs.payments.EnableWalletAutoDetecting"
  );

  if (options && options.firstInstall) {
    await installCashiersRouter();
  }

  await checkAndInstallWallet();
};

export const handleAction = async ({ type, payload }) => {
  console.log("[CASHIER] handleAction", type, payload);

  if (
    !isMembersVertical(payload.appDefinitionId) &&
    !isCommercialVertical(payload.appDefinitionId)
  ) {
    return;
  }

  if (type !== "appInstalled") {
    return;
  }

  await checkAndInstallWallet();
};

const checkAndInstallWallet = async () => {
  if (!isSaveCreditCardEnabled || !isEnableWalletAutoDetectingEnabled) {
    return;
  }

  const isMembersInstalledValue = await isMembersInstalled();
  console.log("[CASHIER] isMembersInstalledValue =", isMembersInstalledValue);
  const isAnyCommercialVerticalInstalledValue = await isAnyCommercialVerticalInstalled();
  console.log(
    "[CASHIER] isAnyCommercialVerticalInstalledValue =",
    isAnyCommercialVerticalInstalledValue
  );

  if (isMembersInstalledValue && isAnyCommercialVerticalInstalledValue) {
    console.log("[CASHIER] Ready for Wallet being installed");

    const isWalletInstalledValue = await isWalletInstalled();
    console.log("[CASHIER] isWalletInstalled =", isWalletInstalledValue);

    if (isInstallingLocked()) {
      console.log("[CASHIER] Installing is locked, aborting");
      return;
    }

    // After adding any commercial vertical 'handleAction' will trigger twice in async way
    // we need to prevent twice Wallet installing and registering vertical's dependencies
    // for correct Wallet removing by MA
    lockInstalling();

    if (!isWalletInstalledValue) {
      console.log("[CASHIER] Installing Wallet");
      await installWallet();
    }

    await installWalletDependencies();
    await editorSDK.document.save();

    unlockInstalling();
  }
};

export const getAppManifest = () => ({});
export const onEvent = data => console.log("[CASHIER] onEvent", data);
export const getControllerPresets = async () => [];
