import { AppId } from "@wix/cashier-common/dist/src/enums/AppId";
import { walletPageId, walletRoute } from "./constants";
import { getInstalledCommercialVerticals, getMembersAPI } from "./appsUtils";
import { appToken, editorSDK } from "./context";

let installInProgress = false;
export const lockInstalling = () => (installInProgress = true);
export const unlockInstalling = () => (installInProgress = false);
export const isInstallingLocked = () => installInProgress;

export async function installWallet() {
  const membersAPI = await getMembersAPI();
  console.log("[CASHIER] MembersAPI =", membersAPI);

  if (!membersAPI) {
    console.log("[CASHIER] MembersArea API was not found");
    return;
  }

  console.log("[CASHIER] Adding Wallet to MembersArea");
  await membersAPI.addApplications([
    {
      appDefinitionId: AppId.Wallet,
      pageId: walletPageId,
      urlOverride: walletRoute,
      social: false,
      showInLoginMenu: true
    }
  ]);
}

export async function installWalletDependencies() {
  const membersAPI = await getMembersAPI();
  console.log("[CASHIER] MembersAPI =", membersAPI);

  if (!membersAPI) {
    console.log("[CASHIER] MembersArea API was not found");
    return;
  }

  const installedCommercialVerticals = await getInstalledCommercialVerticals();
  console.log(
    `[CASHIER] Adding dependantApps = ${JSON.stringify(
      installedCommercialVerticals
    )} to MembersArea`
  );

  installedCommercialVerticals.forEach((appId: AppId) => {
    membersAPI.setDependantApps({
      appId,
      dependencies: [AppId.Wallet]
    });
  });
}

export async function installCashiersRouter() {
  try {
    // Editor doesn't trigger 'handleAction' without existing app's router or data
    await editorSDK.document.routers.add(appToken, {
      prefix: "cashier-site-worker",
      config: { type: "private", patterns: {} }
    });
  } catch (e) {
    // Can't add a router
    // or router is already exists
  }
}
