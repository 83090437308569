import { appToken, editorSDK } from "./context";
import { AppId } from "@wix/cashier-common/dist/src/enums/AppId";

export const commercialVerticals: AppId[] = [
  AppId.Stores,
  AppId.Bookings,
  AppId.Events
  // AppId.Hotels,
  // AppId.Music,
  // AppId.Video,
  // AppId.ArtStore,
  // AppId.WixPaidPlans
];

export const getPublicAPI = (appDefinitionId): Promise<any> =>
  editorSDK.application.getPublicAPI(appToken, { appDefinitionId });

// `await editorSDK.tpa.isApplicationInstalled(appToken, { appDefinitionId: AppId.Members })` always returns false
// Donatas Dautartas suggested this solution
export const getMembersAPI = () => getPublicAPI(AppId.Members);
export const isMembersInstalled = async () => !!(await getMembersAPI());

export const isWalletInstalled = () =>
  editorSDK.tpa.isApplicationInstalled(appToken, {
    appDefinitionId: AppId.Wallet
  });

export const isAppInstalled = (appDefinitionId: AppId): Promise<boolean> =>
  editorSDK.tpa.isApplicationInstalled(appToken, {
    appDefinitionId
  });

export const isMembersVertical = appDefinitionId =>
  appDefinitionId === AppId.Members;

export const isCommercialVertical = appDefinitionId =>
  commercialVerticals.includes(appDefinitionId);

export const isAnyCommercialVerticalInstalled = (): Promise<boolean> =>
  new Promise(resolve => {
    const promiseCache = {};
    commercialVerticals.forEach(vertical => (promiseCache[vertical] = null));

    commercialVerticals.forEach(vertical =>
      // Should we change it to isAppSectionInstalled? section_ids, guid
      isAppInstalled(vertical)
        .catch(() => false)
        .then(installed => {
          promiseCache[vertical] = installed;

          if (installed) {
            resolve(true);
            return;
          }

          if (Object.values(promiseCache).every(value => value === false)) {
            resolve(false);
          }
        })
    );
  });

export const getInstalledCommercialVerticals = async (): Promise<AppId[]> => {
  const result: AppId[] = [];
  for (const appId of commercialVerticals) {
    const isInstalled = await isAppInstalled(appId);
    if (isInstalled) {
      result.push(appId);
    }
  }
  return result;
};
