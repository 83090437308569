import Experiments from "@wix/wix-experiments";
import { baseUrl } from "./constants";

let experiments;

export const fetchExperiments = async (): Promise<Experiments> => {
  if (!experiments) {
    experiments = new Experiments({
      baseUrl,
      scope: "cashier"
    });
  }

  await experiments.ready();

  return experiments;
};
